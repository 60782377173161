import NavbarSectionBS from "../components/Navbar/NavbarSectionBS";
import HeroSection from "../components/Hero/HeroSection";
import OurServices from "../components/MainBody/OurServices";
import AboutUs from "../components/MainBody/AboutUs";
import CarouselSection from "../components/MainBody/CarouselSection";
import PriceSection from "../components/MainBody/PriceSection";
// import LineChart from "../components/MainBody/LineChart";

// import OurStatistics from "../components/MainBody/OurStatistics";
// import OurTeam from "../components/MainBody/OurTeam";
// import ContactSection from "../components/Contact/ContactSection";
import FooterSection from "../components/Footer/FooterSection";

const LandingPage = () => {
	return (
		<>
			<NavbarSectionBS />
			<HeroSection />
			<OurServices />
			<AboutUs />
			<CarouselSection />
			<PriceSection />
			{/* <LineChart /> */}
			{/* <OurStatistics /> */}
			{/* <OurTeam /> */}
			{/* <ContactSection /> */}
			<FooterSection />
		</>
	);
};

export default LandingPage;
